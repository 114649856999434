<template>
  <v-col cols="12" sm="10" md="8" lg="6" xl="6">
    <v-card flat>
      <v-card-title>
        <v-row align="center" justify="center">
          <h3> {{ $t('views.constants.signIn') }} </h3>
        </v-row>
      </v-card-title>
      <v-card-subtitle>
        <v-row align="center" justify="center">
          <h4> {{ $t('views.constants.toContinue') }} </h4>
        </v-row>
      </v-card-subtitle>
      <v-card-text>
        <app-form :fields="fields" :submit-button="submitButton" disable-cancel-button
                  @submitted="handleSubmit" @clear-server-errors="clearServerErrors" />
          <p class="mt-3 text-center">{{ $t('views.constants.resetPassword.click') }}
            <a @click="resetPassword">{{ $t('views.constants.resetPassword.here') }}</a>
            {{ $t('views.constants.resetPassword.reset') }}
          </p>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
import {mapActions} from 'vuex';
import moment from 'moment-timezone';

export default {
  data() {
    return {
      moment,
      invalidCredentials: false,
      fields: {
        email: {
          type: 'email',
          key: 'email',
          value: '',
          label: this.$t('models.user.attributes.email'),
          rules: [{
            name: 'required'
          }, {
            name: 'email'
          }],
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        },
        password: {
          type: 'password',
          key: 'password',
          value: '',
          label: this.$t('models.user.attributes.password'),
          rules: [{
            name: 'required'
          }, {
            name: 'min',
            length: 8
          }],
          classes: 'col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 py-0',
          serverErrors: []
        }
      },
      submitButton: {
        label: this.$t('actions.login'),
        block: true
      }
    };
  },
  methods: {
    ...mapActions(['login']),
    handleSubmit(data) {
      data = {...data, timezone: this.moment.tz.guess()};
      this.login({data: data, targetPath: this.$route.query.to}).then((response) => {
        if (!this.successCode(response.status)) {
          this.invalidCredentials = this._.every(response.errors, {code: this._.snakeCase('invalidCredentials')});
          this.displayInlineServerErrors(this.fields, response.errors);
        }
      });
    },
    clearServerErrors() {
      if (this.invalidCredentials) {
        this._.map(this.fields, (field) => {
          field.serverErrors = [];
        });
        this.invalidCredentials = false;
      }
    },
    resetPassword() {
      this.$router.push({name: 'ResetPassword'});
    }
  }
};
</script>
